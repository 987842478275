import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import Button from "@ui-kit/Button";

import { isAdmin } from "@selectors";
import { useUserStore } from "@store/UserStore";
import { BoolItem } from "@pages/DeepDivePanel/components/BoolItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import { Models } from "@services/api";

const FeaturesAndAmenities: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="features-and-amenities-dialog"
      tier={facility.subscriptionTier}
      title="Features & amenities"
    >
      <div css={css.root}>
        <BoolItem
          label="Credit cards"
          state={facility.featuresInfo?.creditCards}
          data-test="creditCards-display"
        />
        <BoolItem
          label="Stroller or car seat storage"
          state={facility.featuresInfo?.strollerStorage}
          data-test="strollerStorage-display"
        />
        <BoolItem
          label="On-site playground"
          state={facility.featuresInfo?.playground}
          data-test="playground-display"
        />

        <Spacer size="small" />

        <BoolItem
          label="Parking lot"
          state={facility.featuresInfo?.parkingLot}
          data-test="parkingLot-display"
        />
        <BoolItem
          label="Uniforms"
          state={facility.featuresInfo?.uniforms}
          data-test="uniforms-display"
        />
        <BoolItem
          label="Bus service"
          state={facility.featuresInfo?.busService}
          data-test="busService-display"
        />

        <Spacer size="small" />

        <BoolItem
          label="Potty training"
          state={facility.featuresInfo?.pottyTraining}
          data-test="pottyTraining-display"
        />
        <Typography italic data-test="pottyTrainingDescription-display">
          {facility.featuresInfo?.pottyTrainingDescription}
        </Typography>

        <Spacer size="medium" />

        <BoolItem
          label="Naptime"
          state={facility.featuresInfo?.naptime}
          data-test="naptime-display"
        />
        <Typography italic data-test="naptimeDescription-display">
          {facility.featuresInfo?.naptimeDescription}
        </Typography>

        {!isNil(facility.featuresInfo?.mobileParentApp) &&
          facility.featuresInfo?.mobileParentApp !==
            Models.MobileParentApp.No && (
            <Fragment>
              <Spacer size="medium" />
              <Typography gutterBottom data-test="mobileParentApp-display">
                <Typography bolded variant="inherit">
                  Mobile parent app:
                </Typography>{" "}
                {facility.featuresInfo.mobileParentApp ===
                Models.MobileParentApp.BrightWheel ? (
                  "powered by BrightWheel"
                ) : facility.featuresInfo.mobileParentApp ===
                  Models.MobileParentApp.ClassDojo ? (
                  "powered by ClassDojo"
                ) : facility.featuresInfo.mobileParentApp ===
                  Models.MobileParentApp.Tadpoles ? (
                  "powered by Tadpoles"
                ) : (
                  <Typography bolded variant="inherit" color="success">
                    Y
                  </Typography>
                )}
              </Typography>
              <Typography paragraph italic>
                This facility uses an app to capture and share kids' activities
                with families. Parents can get updates about their kids
                throughout the day
              </Typography>
              <div
                css={{
                  padding: "0 15px",
                }}
              >
                <Typography paragraph>
                  <span css={{ textDecoration: "underline" }}>
                    Photo sharing
                  </span>
                  : watch your child's day unfold with photo updates.
                </Typography>
                <Typography paragraph>
                  <span css={{ textDecoration: "underline" }}>
                    Digital check-in
                  </span>
                  : use a personal passcode to digitally check-in.
                </Typography>
                <Typography paragraph>
                  <span css={{ textDecoration: "underline" }}>
                    Pick-up / drop-off
                  </span>
                  : add approved adults for pick-up & drop-off, and track when
                  your child is checked in or out.
                </Typography>
                <Typography>
                  <span css={{ textDecoration: "underline" }}>
                    Paperless billing
                  </span>
                  : make tuition payments directly from your bank account or
                  credit card.
                </Typography>
              </div>
            </Fragment>
          )}

        {!isNil(facility.featuresInfo?.videoStreaming) &&
          facility.featuresInfo?.videoStreaming !==
            Models.VideoStreaming.No && (
            <Fragment>
              <Spacer size="medium" />
              <Typography gutterBottom data-test="videoStreaming-display">
                <Typography bolded variant="inherit">
                  Live video streaming:
                </Typography>{" "}
                {facility.featuresInfo.videoStreaming ===
                Models.VideoStreaming.WatchMeGrow ? (
                  "powered by WatchMeGrow"
                ) : facility.featuresInfo.videoStreaming ===
                  Models.VideoStreaming.PbAndJ ? (
                  "powered by PB&J"
                ) : (
                  <Typography bolded variant="inherit" color="success">
                    Y
                  </Typography>
                )}
              </Typography>
              <Typography paragraph italic>
                This facility offers video streaming. Via a secure portal,
                parents can observe their kids throughout the day
              </Typography>
              <div
                css={{
                  padding: "0 15px",
                }}
              >
                <Typography paragraph>
                  <span css={{ textDecoration: "underline" }}>
                    Connect from anywhere
                  </span>
                  : in real time, watch your child's day from anywhere in the
                  world
                </Typography>
                <Typography paragraph>
                  <span css={{ textDecoration: "underline" }}>HD Video</span>:
                  high quility video for the best viewing experience
                </Typography>
                <Typography>
                  <span css={{ textDecoration: "underline" }}>Security</span>:
                  encrypted video stream, with screenshot and video recording
                  protection
                </Typography>
              </div>
            </Fragment>
          )}

        {(facility.isEditable || isAdmin(user)) && (
          <>
            <Spacer size="md" />
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Pro
                  ? "black"
                  : "primary"
              }
              variant="outlined"
              fullWidth
              size="large"
              link={`/map/${facility.id}/edit/features-and-amenities`}
            >
              Edit features & amenities
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default FeaturesAndAmenities;
